<template>
	<div class="text-center">
		<div :class="{ 'py-6': $vuetify.breakpoint.mdAndUp, 'py-5': $vuetify.breakpoint.smAndDown }"></div>
		<div class="check-mark-wrapper">
			<div class="check-mark-inner">
				<v-icon class="check-mark-icon" size="100px" color="#39b54a">mdi-check-circle</v-icon>
			</div>
		</div>
		<div :class="{ 'py-5': $vuetify.breakpoint.mdAndUp, 'py-2': $vuetify.breakpoint.smAndDown }"></div>

		<CategoryCard :category="categorySelected" :step="3" class="mx-auto" />
		<div :class="{ 'py-5': $vuetify.breakpoint.mdAndUp, 'py-2': $vuetify.breakpoint.smAndDown }"></div>
		<v-btn :to="{ name: 'Dashboard' }" class="actionButton mb-2" dark large>Mon espace perso</v-btn>
		<p class="ortho-description mt-2">Dès {{ dateDisplay }}, tu recevras ta première session dans tes mails !</p>
	</div>
</template>

<script>
import moment from 'moment';
import CategoryCard from './CategoryCard.vue';

export default {
	name: 'SuccessCategorySelected',
	components: { CategoryCard },
	props: { categorySelected: Object },
	computed: {
		dateDisplay() {
			if (moment().format('e') == 5 || moment().format('e') == 6) return 'lundi';
			else return 'demain';
		},
	},
};
</script>

<style lang="scss" scoped>
.check-mark-wrapper {
	height: 100px;
	width: 100%;

	.check-mark-inner {
		background: white;
		width: 80px;
		height: 80px;
		margin: 0 auto;
		position: relative;
		border-radius: 50%;
	}
	.check-mark-icon {
		position: absolute;
		left: -10px;
		top: -10px;
	}
}

.actionButton {
	font-size: 20px;
}

@media screen and (max-width: 600px) {
	.actionButton {
		font-size: 15px;
	}
}
</style>
