<template>
	<div>
		<div v-if="!error">
			<div class="background" :class="{ 'step-1-bg-color': step === 1, 'step-2-3-bg-color': step > 1 }"></div>
			<v-btn
				dark
				large
				color="black"
				@click="nextStep()"
				v-if="step == 1 && formationInfos.last_category == null && formationInfos.formation_finished != true"
				class="button-continue hvr-grow"
				>Continuer</v-btn
			>
			<v-col class="pt-0 pb-0" :class="{ 'pt-12': formationInfos.last_category != null }">
				<v-stepper v-model="step">
					<v-col class="pt-0 pb-0">
						<v-col
							offset-md="3"
							xl="6"
							md="6"
							sm="12"
							cols="12"
							class="pt-0"
							v-if="formationInfos.last_category == null"
						>
							<v-stepper-header>
								<v-stepper-step :complete="step > 1" step="1">Rapport</v-stepper-step>
								<v-divider></v-divider>
								<v-stepper-step :complete="step > 2" step="2">Nouveau programme</v-stepper-step>
								<v-divider></v-divider>
								<v-stepper-step step="3">Confirmation</v-stepper-step>
							</v-stepper-header>
						</v-col>
					</v-col>
					<Congrats :profileInfos="profileInfos" :categoryName="categoryName" :step="step" />

					<Badges v-if="step === 1" class="pb-3 pt-3" />

					<v-stepper-items>
						<v-stepper-content step="1" class="pa-0">
							<div
								class="div-step mb-6 pl-2 pr-2 pl-md-0 pr-md-0"
								:class="{ 'mb-12': formationInfos.last_category != null }"
							>
								<Step1
									:totalScoreInPercent="moyenneScoreInPercent"
									:globalScoreRules="globalScoreRules"
									:globalTargetScoreRules="globalTargetScoreRules"
									:scoresRules="scoresRules"
								/>
							</div>
						</v-stepper-content>

						<v-stepper-content
							step="2"
							v-if="formationInfos.last_category == null"
							:class="{ 'mt-10': $vuetify.breakpoint.mdAndUp }"
						>
							<div class="div-step mt-10 mb-6" :class="{ 'mb-12': formationInfos.last_category != null }">
								<component
									:is="initStep2"
									:formationId="formationInfos._id"
									:listCategories="categoriesWithFullDetails"
									@categorySelected="onCategorySelected"
									@selectionSucceed="onSelectionSucceed"
								/>
							</div>
						</v-stepper-content>

						<v-stepper-content step="3" class="pb-0 pt-0" v-if="formationInfos.last_category == null">
							<div class="div-step mb-6" :class="{ 'mb-12': formationInfos.last_category != null }">
								<Step3 :categorySelected="categorySelected" v-if="step === 3" />
							</div>
						</v-stepper-content>
					</v-stepper-items>
				</v-stepper>
			</v-col>
			<FullLoader v-if="loading" />
		</div>
		<Login v-else />
	</div>
</template>

<script>
import Step1 from './Step1';
import Step3 from './Step3';
import Congrats from './Congrats';
import Badges from './Badges';
import Login from '../../Login';
import FullLoader from '@/components/other/FullLoader.vue';
import { mapGetters } from 'vuex';

export default {
	name: 'ReportCategoryIndex',
	components: {
		Step1,
		Step3,
		Congrats,
		Badges,
		Login,
		FullLoader,
	},
	data() {
		return {
			loading: false,
			step: 1,
			categorySelected: {},
			initStep2: null,
			categoryName: '',
			error: false,
			categoriesWithFullDetails: [],
		};
	},
	computed: {
		...mapGetters('reportCategory', {
			moyenneScoreInPercent: 'moyenneScoreInPercent',
			globalScoreRules: 'globalScoreRules',
			globalTargetScoreRules: 'globalTargetScoreRules',
			scoresRules: 'scoresRules',
		}),
		...mapGetters('profile', {
			profileInfos: 'profileInfos',
			formationInfos: 'formationInfos',
			listCategories: 'listCategories',
		}),
	},
	async created() {
		const { step } = this.$route.query;
		if (Number(step) === 2) {
			this.loading = true;
			this.nextStep();
		}

		try {
			await this.$store.dispatch('reportCategory/getCategoryReport', {
				idFormation: this.$route.params.idFormation,
				idCategory: this.$route.params.idCategory,
			});
			await this.$store.dispatch('profile/getProfileAndExerciseFormationInfos');
			this.categoryName = this.scoresRules[0].infosExo[0].exercices_id.cat_id.name;
			this.loading = false;

			const data = await this.$store.dispatch('rules/getRulesOfParcours', {
				parcoursId: this.formationInfos.parcours_id,
			});
			const categoriesWithExercisesAndRules = data.parcours.categories;
			this.categoriesWithFullDetails = this.listCategories.map((category) => {
				const { exercises } = categoriesWithExercisesAndRules.find((c) => c._id === category._id);
				return { ...category, exercises };
			});
		} catch (error) {
			this.error = true;
		}
	},
	methods: {
		onSelectionSucceed(value) {
			if (value == true) this.step = 3;
		},
		onCategorySelected(value) {
			this.categorySelected = value;
		},
		async nextStep() {
			this.step = 2;
			this.initStep2 = async () => await import(`./Step2`);
		},
	},
};
</script>

<style lang="scss" scoped>
.div-step {
	max-width: 800px;
	margin: 0 auto;
}
.background {
	position: absolute;
	left: 0px;
	height: 50vh;
	width: 100%;
	top: 0;
}

.step-1-bg-color {
	background-color: #25b29b;
}
.step-2-3-bg-color {
	background-color: #120136;
}

::v-deep {
	.theme--light.v-stepper {
		background: transparent;
		box-shadow: none;
		.v-divider {
			border-color: white;
			margin: 0px;
		}
		.v-stepper__header {
			box-shadow: none;
			.v-stepper__label {
				color: white;
			}
			.v-stepper__step__step {
				background-color: white !important;
				border-color: white !important;
				color: #25b29b;
			}
		}
	}
	.theme--light.v-stepper .v-stepper__step__step .v-icon {
		color: #25b29b;
	}
}
.button-continue {
	position: fixed;
	bottom: 30px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
	width: 350px;
}
@media only screen and (max-width: 600px) {
	.div-step {
		width: 100%;
		margin: 0 auto;
	}
	.button-continue {
		width: 250px;
	}
}
</style>
