<template>
	<v-container fluid>
		<ReportCategoryComponent></ReportCategoryComponent>
	</v-container>
</template>

<script>
import ReportCategoryComponent from '../../components/reports/category/Index';

export default {
	name: 'ReportCategory',
	components: {
		ReportCategoryComponent,
	},
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
	.container {
		padding: 0px;
	}
}
</style>
