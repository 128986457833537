<template>
	<div class="full-loader"></div>
</template>

<script>
export default {
	name: 'FullLoader',
};
</script>
<style scoped>
.full-loader {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: #212121;
	opacity: 0.3;
	z-index: 10;
}
</style>
